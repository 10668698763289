import { useMutation, useQuery } from "@apollo/client";
import { gql } from "../../../__generated__";
import { error } from "console";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextInput } from "../../atoms/Inputs/TextInput";
import { Button } from "../../atoms/Buttons/Button";

const GET_PROJECTS = gql(`
  query GetProjects {
    getProjects {
      id
      title
      description
      primaryColour
    }
  }
`);

const MUTATION_CREATE_TIME_DOCUMENT = gql(`
  mutation CreateTimeDocument($title: String!, $description: String, $projectId: String!, $startTime: String!, $endTime: String!) {
    createTimeDocument(title: $title, description: $description, projectId: $projectId, startTime: $startTime, endTime: $endTime) {
      id
      title
      startTime
      endTime
    }
  }
`);

interface IFormInputs {
  title: string;
  description?: string;
  projectId?: string;
  startTime: string;
  endTime: string;
}

const TimeDocumentForm = () => {
  const [
    createTimeDocument,
    {
      data: createTimeDocumenttData,
      loading: createTimeDocumentLoading,
      error: createTimeDocumentError,
    },
  ] = useMutation(MUTATION_CREATE_TIME_DOCUMENT);

  const { loading, error, data } = useQuery(GET_PROJECTS);

  const projects = data?.getProjects ?? [];

  const { register, handleSubmit } = useForm<IFormInputs>();
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const startTimeUtc = new Date(data.startTime).toUTCString();
    const endTimeUtc = new Date(data.endTime).toUTCString();
    data.title &&
      data.startTime &&
      data.endTime &&
      data.projectId &&
      createTimeDocument({
        variables: {
          title: data.title,
          description: data.description,
          projectId: data.projectId,
          startTime: startTimeUtc,
          endTime: endTimeUtc,
        },
      });
  };

  const formOptions = () => {
    return projects.map((project: any) => {
      return (
        <option key={project.id} value={project.id}>
          {project.title}
        </option>
      );
    });
  };
  if (createTimeDocumentLoading || loading) return <p>Loading...</p>;
  if (createTimeDocumentError)
    return <p>Error &#x1F614; {createTimeDocumentError.message}</p>;

  return (
    <>
      {createTimeDocumenttData && createTimeDocumenttData.createTimeDocument ? (
        <p>Created!</p>
      ) : null}
      <div className="rounded p-8">
        <form className="grid gap-y-2" onSubmit={handleSubmit(onSubmit)}>
          <TextInput label="Title" useFormRegister={register("title")} />
          <TextInput
            label="Start time"
            type="datetime-local"
            useFormRegister={register("startTime")}
          />
          <TextInput
            label="End time"
            type="datetime-local"
            useFormRegister={register("endTime")}
          />
          <TextInput
            label="Description"
            useFormRegister={register("description")}
          />
          <select className="border" {...register("projectId")}>
            {formOptions()}
          </select>
          <div>
            <Button text="Submit" type="submit" />
          </div>
        </form>
      </div>
    </>
  );
};

export default TimeDocumentForm;
