import { Auth } from "aws-amplify";
import { ProjectCollection } from "../../molecules/ProjectCollection";
import { ProjectForm } from "../../organisms/ProjectForm";
import { Home } from "../Home";
import { Link } from "react-router-dom";
import { Button } from "../../atoms/Buttons/Button";

const ProjectPage = () => {
  return (
    <>
      <div className="p-8">
        <div className="flex flex-row-reverse justify-between mb-8 pr-6">
          <Link to="/projects/new">
            <Button text="Create" icon="plus" />
          </Link>
          <h1 className="font-bold underline text-left">Projects</h1>
        </div>
        <div>
          <ProjectCollection />
        </div>
      </div>
    </>
  );
};

export default ProjectPage;
