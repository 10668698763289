import { useQuery } from "@apollo/client";
import { gql } from "../../../__generated__";
import { useEffect, useState } from "react";
import { Loader } from "@aws-amplify/ui-react";

const GET_PROJECTS_WEEKLY_REPORT = gql(`
  query GetProjectsWeeklyReport($day: String!) {
    getProjectsWeeklyReport(day: $day) {
      startTime
      endTime
      totalHours
      projectReports {
        project {
          id
          title
          description
          primaryColour
        }
        hours
        percentage
      }
    }
  }
`);

const ReportPage = () => {
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString()
  );
  const { loading, error, data } = useQuery(GET_PROJECTS_WEEKLY_REPORT, {
    variables: { day: currentDate },
  });
  const getProjectWeeklyReport = data?.getProjectsWeeklyReport;
  console.log(getProjectWeeklyReport);
  console.log(error);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    setStartDate(
      new Date(getProjectWeeklyReport?.startTime || "").toDateString()
    );
    setEndDate(new Date(getProjectWeeklyReport?.endTime || "").toDateString());
  }, [getProjectWeeklyReport]);

  const prevWeekHandler = () => {
    const curr = new Date(currentDate);
    const newDate = new Date(
      curr.getFullYear(),
      curr.getMonth(),
      curr.getDate() - 7
    );
    setCurrentDate(newDate.toLocaleDateString());
  };
  const nextWeekHandler = () => {
    const curr = new Date(currentDate);
    const newDate = new Date(
      curr.getFullYear(),
      curr.getMonth(),
      curr.getDate() + 7
    );
    setCurrentDate(newDate.toLocaleDateString());
  };
  if (loading)
    return (
      <p>
        <Loader></Loader>
      </p>
    );
  if (error) return <p>Error &#x1F614; {error.message}</p>;

  return (
    <div className="p-8">
      <div className="flex justify-between mb-8 pr-6">
        <h1 className="font-bold underline">Report</h1>
      </div>

      <div className="flex justify-between">
        <div>
          <button
            className="border p-2 rounded hover:bg-gray-100"
            onClick={() => prevWeekHandler()}
          >
            Previous Week
          </button>
        </div>
        <div>
          <button
            className="border p-2 rounded hover:bg-gray-100"
            onClick={() => nextWeekHandler()}
          >
            Next Week
          </button>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex justify-between">
          <div>
            <span className="font-bold">Start:</span> {startDate}
          </div>
          <div>
            <span className="font-bold">End:</span> {endDate}
          </div>
          <div>
            <span className="font-bold">Total Hours:</span>{" "}
            {getProjectWeeklyReport?.totalHours}
          </div>
        </div>
      </div>
      {getProjectWeeklyReport?.projectReports && (
        <>
          <div className="mt-8">
            <div className="flex justify-between font-bold border-b">
              <div>Project</div>
              <div>Hours</div>
              <div>Percentage</div>
            </div>
            {getProjectWeeklyReport?.projectReports.map((projectReport) => {
              return (
                <div className="flex justify-between">
                  <div>project: {projectReport.project.title}</div>

                  <div>hours: {projectReport.hours}</div>
                  <div>percentage: {projectReport.percentage}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {/* {getProjectWeeklyReport?.projectReports[0]} */}
    </div>
  );
};

export default ReportPage;
