import "./App.css";
import { Home } from "./components/pages/Home";
import { Login } from "./components/pages/Login";
import { Authenticator } from "@aws-amplify/ui-react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "./providers/RequireAuth";
import { ApolloWrapper } from "./providers/ApolloWrapper";
import { LayoutWrapper } from "./providers/LayoutWrapper";
import { CalendarPage } from "./components/pages/CalendarPage";
import { ProjectPage } from "./components/pages/ProjectPage";
import { ProjectCreatePage } from "./components/pages/ProjectCreatePage";
import { ProjectEditPage } from "./components/pages/ProjectEditPage";
import { EventCreatePage } from "./components/pages/EventCreatePage";
import { ReportPage } from "./components/pages/ReportPage";
import { TimeDocumentFormEdit } from "./components/organisms/TimeDocumentFormEdit";

function App() {
  return (
    <div className="App">
      <ApolloWrapper>
        <Authenticator.Provider>
          <main>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <LayoutWrapper>
                      <Home />
                    </LayoutWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path="/projects"
                element={
                  <RequireAuth>
                    <LayoutWrapper>
                      <ProjectPage />
                    </LayoutWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path="/projects/:projectId"
                element={
                  <RequireAuth>
                    <LayoutWrapper>
                      <ProjectEditPage />
                    </LayoutWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path="/projects/new"
                element={
                  <RequireAuth>
                    <LayoutWrapper>
                      <ProjectCreatePage />
                    </LayoutWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path="/calendar"
                element={
                  <RequireAuth>
                    <LayoutWrapper>
                      <CalendarPage />
                    </LayoutWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path="/events/new"
                element={
                  <RequireAuth>
                    <LayoutWrapper>
                      <EventCreatePage />
                    </LayoutWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path="/events/:eventId"
                element={
                  <RequireAuth>
                    <LayoutWrapper>
                      <TimeDocumentFormEdit />
                    </LayoutWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path="/reports"
                element={
                  <RequireAuth>
                    <LayoutWrapper>
                      <ReportPage />
                    </LayoutWrapper>
                  </RequireAuth>
                }
              />
            </Routes>
          </main>
        </Authenticator.Provider>
      </ApolloWrapper>
    </div>
  );
}

export default App;
