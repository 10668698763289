import { useMutation, useQuery } from "@apollo/client";
import { gql } from "../../../__generated__";
import { useForm, SubmitHandler } from "react-hook-form";
import { EventColourCssMap } from "../../../utils/types";
import { ProjectCard } from "../ProjectCard";
import { Loader } from "@aws-amplify/ui-react";

const GET_PROJECTS = gql(`
  query GetProjects {
    getProjects {
      id
      title
      description
      primaryColour
    }
  }
`);

const ProjectCollection = () => {
  const { loading, error, data } = useQuery(GET_PROJECTS);

  const projects = data?.getProjects;

  console.log(projects);
  if (loading)
    return (
      <p>
        <Loader></Loader>
      </p>
    );
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <div className="flex justify-between border-b pb-2 font-bold">
        <div>Name</div>
        <div>Description</div>
      </div>
      {projects && (
        <>
          <div>
            {projects.map((project: any) => [
              <ProjectCard project={project} />,
            ])}
          </div>
        </>
      )}
    </>
  );
};

export default ProjectCollection;
