import { TimeDocumentForm } from "../../organisms/TimeDocumentForm";

const EventCreatePage = () => {
  return (
    <div>
      <h1 className="font-bold underline"> Create New Event</h1>
      <TimeDocumentForm />
    </div>
  );
};

export default EventCreatePage;
