import { CustomCalendar } from "../../organisms/CustomCalendar";
import { Link } from "react-router-dom";
import { Button } from "../../atoms/Buttons/Button";

const CalendarPage = () => {
  return (
    <div className="p-8">
      <div className="flex flex-row-reverse justify-between mb-8 pr-6">
        <Link to="/events/new">
          <Button text="Create" icon="plus" />
        </Link>

        <h1 className="font-bold underline">Calendar</h1>
      </div>
      <CustomCalendar />
    </div>
  );
};

export default CalendarPage;
