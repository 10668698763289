import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router";

const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  const prevPathname = location.state?.from?.pathname || null;
  const prevSearch = location.state?.from?.search || null;
  const from = prevSearch
    ? `${prevPathname}${prevSearch}`
    : prevPathname || "/";

  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [from, navigate, route]);

  return (
    <>
      <Authenticator
        loginMechanisms={["email"]}
        // socialProviders={['google']}
        signUpAttributes={["email"]}
        className="mt-5"
      ></Authenticator>
    </>
  );
};

export default Login;
