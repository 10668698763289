import { Link, useLocation, useParams } from "react-router-dom";
// import card from '../../../icons/card.svg';
// import cog from '../../../icons/cog.svg';
// import document from '../../../icons/document.svg';
// import help from '../../../icons/help.svg';
// import home from '../../../icons/home.svg';
// import money from '../../../icons/money.svg';
// import panel from '../../../icons/panel.svg';
// import transaction from '../../../icons/transaction.svg';
import {
  FcBarChart,
  FcBriefcase,
  FcBullish,
  FcCalendar,
  FcHome,
  FcSportsMode,
} from "react-icons/fc";
const SidebarData = [
  {
    title: "Home",
    path: "/",
    logo: <FcHome />,
  },
  {
    title: "Calendar",
    path: "/calendar",
    logo: <FcCalendar />,
  },
  {
    title: "Projects",
    path: "/projects",
    logo: <FcBriefcase />,
  },
  {
    title: "Reports",
    path: "/reports",
    logo: <FcBarChart />,
  },
];
const SidebarBottom = [
  {
    title: "Sign out",
    path: "/signout",
    logo: <FcSportsMode />,
  },
];

const Navbar = () => {
  const { id } = useParams();
  const location = useLocation();
  const { pathname } = location;

  return (
    <nav className="bg-blue-200 min-h-screen grid grid-cols-1 content-between">
      <ul className="mt-4">
        {SidebarData.map((item, index) => {
          const link = `${item.path}`;
          const isActive = pathname === link;
          return (
            <li key={index} className="flex items-center h-12 px-2">
              <Link
                className={`text-black no-underline text-md px-4 py-2 w-full rounded hover:bg-blue-100 flex ${
                  isActive && "bg-pearl"
                }`}
                to={link}
              >
                <div className="self-center object-contain h-3.5 mr-2">
                  {item.logo}
                </div>
                <span>{item.title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
      <ul className="mb-4">
        {SidebarBottom.map((item, index) => {
          const link = `/${id}${item.path}`;
          const isActive = pathname === link;
          return (
            <li key={index} className="flex items-center h-12 px-2">
              <Link
                className={`text-black no-underline text-md px-4 py-2 w-full rounded hover:bg-blue-100 flex ${
                  isActive ? "bg-pearl" : ""
                }`}
                to={link}
              >
                <div className="self-center object-contain h-3.5 mr-2">
                  {item.logo}
                </div>
                <span>{item.title}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
