import { useMutation } from "@apollo/client";
import { gql } from "../../../__generated__";
import { error } from "console";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextInput } from "../../atoms/Inputs/TextInput";
import { Button } from "../../atoms/Buttons/Button";
import { projectColours } from "../../../utils/types";
import { Loader } from "@aws-amplify/ui-react";

const MUTATION_CREATE_PROJECT = gql(`
  mutation CreateProject($title: String!, $description: String, $primaryColour: String!) {
    createProject(title: $title, description: $description, primaryColour: $primaryColour) {
      id
      title
      description
      primaryColour
    }
  }
`);

interface IFormInputs {
  title: string;
  description?: string;
  primaryColour: string;
}

const ProjectForm = () => {
  const [
    createProject,
    {
      data: createProjectData,
      loading: createProjectLoading,
      error: createProjectError,
    },
  ] = useMutation(MUTATION_CREATE_PROJECT);

  const { register, handleSubmit } = useForm<IFormInputs>();
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    data.title &&
      data.primaryColour &&
      createProject({
        variables: {
          title: data.title,
          description: data.description,
          primaryColour: data.primaryColour,
        },
      });
  };
  if (createProjectLoading)
    return (
      <p>
        <Loader></Loader>
      </p>
    );
  if (createProjectError)
    return <p>Error &#x1F614; {createProjectError.message}</p>;

  return (
    <>
      {createProjectData && createProjectData.createProject ? (
        <p>Created!</p>
      ) : null}
      <div className="rounded p-8">
        <form className="grid gap-y-2" onSubmit={handleSubmit(onSubmit)}>
          <TextInput label="Title" useFormRegister={register("title")} />
          <TextInput
            label="Description"
            useFormRegister={register("description")}
          />
          <label>Colour</label>
          <div className="flex flex-col px-4 pt-2 pb-1.5 rounded-lg border">
            <select
              className="w-full bg-transparent border-b-transparent border-b-2 focus:outline-none focus:ring-0 focus:border-b-blue-100"
              {...register("primaryColour")}
            >
              {projectColours.map((colour) => {
                return <option value={colour}>{colour}</option>;
              })}
            </select>
          </div>
          <div>
            <Button text="Submit" type="submit" />
          </div>
        </form>
      </div>
    </>
  );
};

export default ProjectForm;
