import { FcPlus } from "react-icons/fc";

type ButtonProps = {
  text: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  active?: boolean;
  variant?: "obvious" | "easy" | "possible" | "ghost" | "disabled" | "pearl";
  icon?: "plus";
  type?: "submit" | undefined;
};
const Button = (props: ButtonProps) => {
  const { text, onClick, className, active, type } = props;
  let { disabled, variant, icon } = props;
  disabled ||= false;
  variant ||= "obvious";

  const buttonStyleBase = {
    obvious: "bg-blue-300 text-gray-900 border border-blue-300",
    easy: "bg-black text-white border border-graphite",
    possible: "bg-sand text-graphite border border-sand",
    ghost: "bg-white text-graphite border border-graphite",
    disabled: "bg-white text-feather border border-feather",
    pearl: "bg-pearl text-graphite border border-pearl",
  };

  const buttonStyleHover = {
    obvious: "hover:bg-blue-200 hover:border-blue-200",
    easy: "hover:bg-white hover:text-graphite",
    possible: "hover:bg-white hover:border-graphite hover:text-graphite",
    ghost: "hover:bg-graphite hover:text-white",
    disabled: "",
    pearl: "hover:bg-white hover:border-pearl hover:text-graphite",
  };

  // If the button is "active" disable hover-over mode
  let buttonStyle = active
    ? buttonStyleBase[variant]
    : `${buttonStyleBase[variant]} ${buttonStyleHover[variant]}`;

  // If disabled change the button style to disabled
  if (disabled) {
    buttonStyle = buttonStyleBase["disabled"];
  }

  const iconsMap = {
    plus: <FcPlus />,
  };

  return (
    <button
      className={`${className || ""} ${buttonStyle} px-6 py-3 rounded-2xl`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <div className="flex">
        {icon && <span className="self-center mr-3">{iconsMap[icon]}</span>}
        {text}
      </div>
    </button>
  );
};

export default Button;
