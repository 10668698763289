import { Header } from "../components/atoms/Header";
import { Navbar } from "../components/atoms/Navbar";

interface LayoutWrapperProps {
  children: JSX.Element;
  withHeader?: boolean;
  withNavbar?: boolean;
}

export function LayoutWrapper({
  children,
  withHeader = true,
  withNavbar = true,
}: LayoutWrapperProps): JSX.Element | null {
  return (
    <div>
      {!withNavbar && !withHeader && children}
      {withHeader && (
        <div className="w-screen top-0 md:hidden">
          <Header />
        </div>
      )}
      {!withNavbar && withHeader && <div className="w-full">{children}</div>}
      {withNavbar && (
        <>
          <div className="md:left-0 left-[-232px] fixed w-1/6">
            <Navbar />
          </div>
          <div className="w-screen md:w-5/6 h-[calc(100vh-4rem)] overflow-y-scroll fixed right-0">
            {children}
          </div>
        </>
      )}
    </div>
  );
}
