import { useMutation, useQuery } from "@apollo/client";
import { gql } from "../../../__generated__";
import { error } from "console";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextInput } from "../../atoms/Inputs/TextInput";
import { Button } from "../../atoms/Buttons/Button";
import { projectColours } from "../../../utils/types";
import { Loader, usePagination } from "@aws-amplify/ui-react";
import { useParams } from "react-router";

const GET_PROJECT = gql(`
    query GetProject($projectId: String!) {
        getProject(projectId: $projectId) {
            id
            title
            description
            primaryColour
        }
    }
`);

const MUTATION_UPDATE_PROJECT = gql(`
  mutation UpdateProject($id: String!, $title: String!, $description: String, $primaryColour: String!) {
    updateProject(id: $id, title: $title, description: $description, primaryColour: $primaryColour) {
      id
      title
      description
      primaryColour
    }
  }
`);

interface IFormInputs {
  id: string;
  title: string;
  description?: string;
  primaryColour: string;
}

const ProjectFormEdit = () => {
  const { projectId } = useParams();
  const [
    updateProject,
    {
      data: updateProjectData,
      loading: updateProjectLoading,
      error: updateProjectError,
    },
  ] = useMutation(MUTATION_UPDATE_PROJECT);
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { projectId: projectId || "" },
  });

  const project = data?.getProject;
  //   const { register, handleSubmit } = useForm<IFormInputs>();
  const values = {
    id: project?.id ?? "",
    title: project?.title ?? "",
    description: project?.description ?? "",
    primaryColour: project?.primaryColour ?? "red",
  };
  const { register, handleSubmit } = useForm<IFormInputs>({
    values,
  });
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    console.log("usbmit");
    console.log(data);
    console.log(data.primaryColour);
    data.title &&
      data.primaryColour &&
      updateProject({
        variables: {
          id: data.id,
          title: data.title,
          description: data.description,
          primaryColour: data.primaryColour,
        },
      });
  };
  if (loading)
    return (
      <p>
        <Loader></Loader>
      </p>
    );
  if (error) return <p>Error &#x1F614; {error.message}</p>;
  if (updateProjectError) <p>Error &#x1F614; {updateProjectError.message}</p>;

  return (
    <>
      {updateProjectData && updateProjectData.updateProject ? (
        <p>Updated!</p>
      ) : null}
      <div className="rounded p-8">
        <form className="grid gap-y-2" onSubmit={handleSubmit(onSubmit)}>
          <TextInput label="Title" useFormRegister={register("title")} />
          <TextInput
            label="Description"
            useFormRegister={register("description")}
          />
          <label>Primary Colour</label>
          <div className="flex flex-col px-4 pt-2 pb-1.5 rounded-lg border">
            <select
              className="w-full bg-transparent border-b-transparent border-b-2 focus:outline-none focus:ring-0 focus:border-b-blue-100"
              {...register("primaryColour")}
            >
              {projectColours.map((colour) => {
                return <option value={colour}>{colour}</option>;
              })}
            </select>
          </div>
          <div>
            <Button text="Submit" type="submit" />
          </div>
        </form>
      </div>
    </>
  );
};

export default ProjectFormEdit;
