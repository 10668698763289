import { Amplify } from "aws-amplify";

// const isLocalhost = Boolean(
//   window.location.hostname === "localhost" ||
//     // [::1] is the IPv6 localhost address.
//     window.location.hostname === "[::1]" ||
//     // 127.0.0.1/8 is considered localhost for IPv4.
//     window.location.hostname.match(
//       /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
//     )
// );

export const initAmplify = () => {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    },
    // Storage: {
    //   AWSS3: {
    //     bucket: process.env.REACT_APP_TRANSACTION_RECEIPTS_S3_BUCKET,
    //     region: process.env.REACT_APP_AWS_REGION,
    //   },
    // },
    // oauth: {
    //   domain: process.env.REACT_APP_COGNITO_AUTH_DOMAIN,
    //   scope: ["openid", "email", "profile", "aws.cognito.signin.user.admin"],
    //   redirectSignIn: isLocalhost
    //     ? "http://localhost:3000/"
    //     : "https://www.shegunn.com/",
    //   redirectSignOut: isLocalhost
    //     ? "http://localhost:3000/"
    //     : "https://www.shegunn.com/",
    //   responseType: "code",
    // },
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_API_URL,
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  });
};
