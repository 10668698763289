import { ProjectForm } from "../../organisms/ProjectForm";

const ProjectCreatePage = () => {
  return (
    <div>
      <h1 className="font-bold underline"> Create New Project</h1>
      <ProjectForm />
    </div>
  );
};

export default ProjectCreatePage;
