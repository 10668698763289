import { ProjectCollection } from "../../molecules/ProjectCollection";
import { ProjectForm } from "../../organisms/ProjectForm";
import { ProjectFormEdit } from "../../organisms/ProjectFormEdit";

const ProjectEditPage = () => {
  return (
    <div>
      <h1 className="font-bold underline"> Edit Project</h1>
      <ProjectFormEdit />
    </div>
  );
};

export default ProjectEditPage;
