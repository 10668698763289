import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

let debounceTime: NodeJS.Timeout;
const debounce = function (callback: (...args: any[]) => void, delay = 1000) {
  return (...args: any[]) => {
    clearTimeout(debounceTime);
    debounceTime = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

type TextInputProps = {
  label: string;
  placeholder?: string;
  useFormRegister?: UseFormRegisterReturn;
  onChangeText?: (value: string) => void;
  prefix?: string;
  debounce?: number;
  disabled?: boolean;
  type?: "text" | "number" | "datetime-local" | undefined;
  value?: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  className?: string;
  hasError?: boolean;
  errorMessage?: string;
};

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      label,
      placeholder,
      useFormRegister,
      onChange,
      value,
      defaultValue,
      className,
      prefix = undefined,
      type = "text",
      hasError = false,
      errorMessage,
    } = props;
    let { required } = props;
    if (required === undefined) {
      required = true;
    }
    const onChangeText = (text: string) => {
      props.debounce &&
        debounce(() => {
          props.onChangeText && props.onChangeText(text);
        }, props.debounce)();
    };

    const borderOutline = hasError ? "border-red-300" : "border-gray-400";
    return (
      <div>
        <div className="text-left mb-2">
          <label className="text-sm text-gray-500 mb-1">{label}</label>
          {!required && <span className="text-gray-900 ml-1">(optional)</span>}
        </div>
        <div
          onChange={(e) => onChangeText((e.target as HTMLInputElement).value)}
          className={`flex flex-col px-4 pt-2 pb-1.5 rounded-lg border ${borderOutline} ${className}`}
        >
          <div className="flex">
            {prefix ? (
              <span className="mr-1 text-gray-900">{prefix}</span>
            ) : null}
            <input
              type={type}
              className="w-full bg-transparent border-b-transparent border-b-2 focus:outline-none focus:ring-0 focus:border-b-blue-100"
              placeholder={placeholder}
              ref={ref}
              value={value === "NaN" ? "" : value}
              defaultValue={defaultValue === "NaN" ? "" : defaultValue}
              {...useFormRegister}
              onChange={onChange}
              disabled={props.disabled}
            />
          </div>
        </div>
        {hasError && <div className="mt-2 text-red-300">{errorMessage}</div>}
      </div>
    );
  }
);

export default TextInput;
