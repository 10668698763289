export type EventColour =
  | "red"
  | "yellow"
  | "pink"
  | "green"
  | "purple"
  | "orange"
  | "blue";

export const projectColours = [
  "red",
  "yellow",
  "pink",
  "green",
  "purple",
  "orange",
  "blue",
];

export const EventColourCssMap = {
  default: "bg-gray-500",
  red: "bg-red-400",
  yellow: "bg-yellow-500",
  pink: "bg-pink-500",
  green: "bg-green-500",
  purple: "bg-green-500",
  orange: "bg-orange-500",
  blue: "bg-blue-400",
};

export const EventColourCssHexMap = {
  default: "#6b7280",
  red: "#ef4444",
  yellow: "#eab308",
  pink: "#ec4899",
  green: "#22c55e",
  purple: "#a855f7",
  orange: "#f97316",
  blue: "#3b82f6",
};
