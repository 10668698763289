import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate, useLocation } from "react-router-dom";

interface RequireAuthProps {
  children: JSX.Element;
}

export function RequireAuth({
  children,
}: RequireAuthProps): JSX.Element | null {
  const location = useLocation();
  const { user, route } = useAuthenticator((context) => [context.route]);
  console.log(route);
  console.log(user);
  if (route !== "authenticated") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
