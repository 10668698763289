/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  mutation CreateTimeDocument($title: String!, $description: String, $projectId: String!, $startTime: String!, $endTime: String!) {\n    createTimeDocument(title: $title, description: $description, projectId: $projectId, startTime: $startTime, endTime: $endTime) {\n      id\n      title\n      startTime\n      endTime\n    }\n  }\n": types.CreateTimeDocumentDocument,
    "\n  query GetTimeDocuments($start: String!, $end: String!, ){\n    getTimeDocuments(start: $start, end: $end) {\n      id\n      title\n      startTime\n      endTime\n    }\n  }\n": types.GetTimeDocumentsDocument,
    "\n  query GetProjects {\n    getProjects {\n      id\n      title\n      description\n      primaryColour\n    }\n  }\n": types.GetProjectsDocument,
    "\n  query GetCurrentWeekTimeDocumentsForCalendar($startDateTime: String!, $timeZoneOffset: Int!) {\n    getCurrentWeekTimeDocuments(startDateTime: $startDateTime, timeZoneOffset: $timeZoneOffset) {\n      id\n      title\n      startTime\n      endTime\n      projectId\n      project {\n        id\n        primaryColour\n      }\n    }\n  }\n": types.GetCurrentWeekTimeDocumentsForCalendarDocument,
    "\n  mutation CreateProject($title: String!, $description: String, $primaryColour: String!) {\n    createProject(title: $title, description: $description, primaryColour: $primaryColour) {\n      id\n      title\n      description\n      primaryColour\n    }\n  }\n": types.CreateProjectDocument,
    "\n    query GetProject($projectId: String!) {\n        getProject(projectId: $projectId) {\n            id\n            title\n            description\n            primaryColour\n        }\n    }\n": types.GetProjectDocument,
    "\n  mutation UpdateProject($id: String!, $title: String!, $description: String, $primaryColour: String!) {\n    updateProject(id: $id, title: $title, description: $description, primaryColour: $primaryColour) {\n      id\n      title\n      description\n      primaryColour\n    }\n  }\n": types.UpdateProjectDocument,
    "\n  query GetTimeDocument($timeDocumentId: String!) {\n    getTimeDocument(timeDocumentId: $timeDocumentId) {\n      id\n      title\n      description\n      projectId\n      startTime\n      endTime\n    }\n  }\n": types.GetTimeDocumentDocument,
    "\n  mutation UpdateTimeDocument($id: String!, $title: String!, $description: String, $projectId: String!, $startTime: String!, $endTime: String!) {\n    updateTimeDocument(id: $id, title: $title, description: $description, projectId: $projectId, startTime: $startTime, endTime: $endTime) {\n        id\n        title\n        description\n        projectId\n        startTime\n        endTime\n    }\n  }\n": types.UpdateTimeDocumentDocument,
    "\n  query GetProjectsWeeklyReport($day: String!) {\n    getProjectsWeeklyReport(day: $day) {\n      startTime\n      endTime\n      totalHours\n      projectReports {\n        project {\n          id\n          title\n          description\n          primaryColour\n        }\n        hours\n        percentage\n      }\n    }\n  }\n": types.GetProjectsWeeklyReportDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateTimeDocument($title: String!, $description: String, $projectId: String!, $startTime: String!, $endTime: String!) {\n    createTimeDocument(title: $title, description: $description, projectId: $projectId, startTime: $startTime, endTime: $endTime) {\n      id\n      title\n      startTime\n      endTime\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTimeDocument($title: String!, $description: String, $projectId: String!, $startTime: String!, $endTime: String!) {\n    createTimeDocument(title: $title, description: $description, projectId: $projectId, startTime: $startTime, endTime: $endTime) {\n      id\n      title\n      startTime\n      endTime\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTimeDocuments($start: String!, $end: String!, ){\n    getTimeDocuments(start: $start, end: $end) {\n      id\n      title\n      startTime\n      endTime\n    }\n  }\n"): (typeof documents)["\n  query GetTimeDocuments($start: String!, $end: String!, ){\n    getTimeDocuments(start: $start, end: $end) {\n      id\n      title\n      startTime\n      endTime\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProjects {\n    getProjects {\n      id\n      title\n      description\n      primaryColour\n    }\n  }\n"): (typeof documents)["\n  query GetProjects {\n    getProjects {\n      id\n      title\n      description\n      primaryColour\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCurrentWeekTimeDocumentsForCalendar($startDateTime: String!, $timeZoneOffset: Int!) {\n    getCurrentWeekTimeDocuments(startDateTime: $startDateTime, timeZoneOffset: $timeZoneOffset) {\n      id\n      title\n      startTime\n      endTime\n      projectId\n      project {\n        id\n        primaryColour\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCurrentWeekTimeDocumentsForCalendar($startDateTime: String!, $timeZoneOffset: Int!) {\n    getCurrentWeekTimeDocuments(startDateTime: $startDateTime, timeZoneOffset: $timeZoneOffset) {\n      id\n      title\n      startTime\n      endTime\n      projectId\n      project {\n        id\n        primaryColour\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateProject($title: String!, $description: String, $primaryColour: String!) {\n    createProject(title: $title, description: $description, primaryColour: $primaryColour) {\n      id\n      title\n      description\n      primaryColour\n    }\n  }\n"): (typeof documents)["\n  mutation CreateProject($title: String!, $description: String, $primaryColour: String!) {\n    createProject(title: $title, description: $description, primaryColour: $primaryColour) {\n      id\n      title\n      description\n      primaryColour\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetProject($projectId: String!) {\n        getProject(projectId: $projectId) {\n            id\n            title\n            description\n            primaryColour\n        }\n    }\n"): (typeof documents)["\n    query GetProject($projectId: String!) {\n        getProject(projectId: $projectId) {\n            id\n            title\n            description\n            primaryColour\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateProject($id: String!, $title: String!, $description: String, $primaryColour: String!) {\n    updateProject(id: $id, title: $title, description: $description, primaryColour: $primaryColour) {\n      id\n      title\n      description\n      primaryColour\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProject($id: String!, $title: String!, $description: String, $primaryColour: String!) {\n    updateProject(id: $id, title: $title, description: $description, primaryColour: $primaryColour) {\n      id\n      title\n      description\n      primaryColour\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetTimeDocument($timeDocumentId: String!) {\n    getTimeDocument(timeDocumentId: $timeDocumentId) {\n      id\n      title\n      description\n      projectId\n      startTime\n      endTime\n    }\n  }\n"): (typeof documents)["\n  query GetTimeDocument($timeDocumentId: String!) {\n    getTimeDocument(timeDocumentId: $timeDocumentId) {\n      id\n      title\n      description\n      projectId\n      startTime\n      endTime\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateTimeDocument($id: String!, $title: String!, $description: String, $projectId: String!, $startTime: String!, $endTime: String!) {\n    updateTimeDocument(id: $id, title: $title, description: $description, projectId: $projectId, startTime: $startTime, endTime: $endTime) {\n        id\n        title\n        description\n        projectId\n        startTime\n        endTime\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTimeDocument($id: String!, $title: String!, $description: String, $projectId: String!, $startTime: String!, $endTime: String!) {\n    updateTimeDocument(id: $id, title: $title, description: $description, projectId: $projectId, startTime: $startTime, endTime: $endTime) {\n        id\n        title\n        description\n        projectId\n        startTime\n        endTime\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetProjectsWeeklyReport($day: String!) {\n    getProjectsWeeklyReport(day: $day) {\n      startTime\n      endTime\n      totalHours\n      projectReports {\n        project {\n          id\n          title\n          description\n          primaryColour\n        }\n        hours\n        percentage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProjectsWeeklyReport($day: String!) {\n    getProjectsWeeklyReport(day: $day) {\n      startTime\n      endTime\n      totalHours\n      projectReports {\n        project {\n          id\n          title\n          description\n          primaryColour\n        }\n        hours\n        percentage\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;