// import closeButton from "../../../images/closeButton.png";
// import zenaLogo from "../../../images/zenaLogo.png";
// import { Navbar } from "../Navbar";
// import { UserMenu } from "../UserMenu";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { Navbar } from "../Navbar";

const Header = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();

  const signOutAndRemoveAccess = () => {
    signOut();
  };

  const handleClick = () => {
    setShowSidebar(!showSidebar);
  };

  const onClose = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    setShowSidebar(false);
  }, [location]);

  return (
    <header className="bg-blue-200 h-10 pl-5 m-0 flex items-center">
      <div className="md:hidden mr-4 md:mr-0">
        <button aria-label="Toggle dropdown menu" onClick={handleClick}>
          ☰
        </button>
      </div>
      {showSidebar && (
        <div className="z-10 left-0 top-6 fixed w-full">
          <div className="flex flex-row-reverse">
            <button onClick={onClose} aria-label="Close dropdown menu">
              <img
                className="w-3 h-3 mr-4 cursor-pointer"
                // src={closeButton}
                alt="Close icon"
              />
            </button>
          </div>
          <Navbar />
        </div>
      )}
      {/* <div className="flex-1 text-left">
        <a className="no-underline" href="/">
          TimeTrackerCo
        </a>
      </div> */}
      {user && (
        <div className="flex-1 text-right pr-5">
          {/* <UserMenu onSignOut={signOutAndRemoveAccess} /> */}
        </div>
      )}
    </header>
  );
};

export default Header;
