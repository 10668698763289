import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { gql } from "../../../__generated__";
import { useForm, SubmitHandler } from "react-hook-form";
import { components } from "@aws-amplify/ui-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  EventColour,
  EventColourCssHexMap,
  EventColourCssMap,
} from "../../../utils/types";
import { redirect, useNavigate } from "react-router";
import { useEffect, useState } from "react";

const GET_CURRENT_WEEK_TIME_DOCUMENTS = gql(`
  query GetCurrentWeekTimeDocumentsForCalendar($startDateTime: String!, $timeZoneOffset: Int!) {
    getCurrentWeekTimeDocuments(startDateTime: $startDateTime, timeZoneOffset: $timeZoneOffset) {
      id
      title
      startTime
      endTime
      projectId
      project {
        id
        primaryColour
      }
    }
  }
`);

const localizer = momentLocalizer(moment);

const CustomCalendar = () => {
  let navigate = useNavigate();
  const [startOfDay, setStartOfDay] = useState(
    new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString()
  );
  const [timeZoneOffset, setTimeZoneOffset] = useState(
    new Date().getTimezoneOffset()
  );

  const [getTimeDocs, { loading, error, data }] = useLazyQuery(
    GET_CURRENT_WEEK_TIME_DOCUMENTS,
    {
      variables: {
        startDateTime: startOfDay,
        timeZoneOffset: timeZoneOffset,
      },
    }
  );

  useEffect(() => {
    console.log("call-use-effect");
    getTimeDocs();
  }, [startOfDay, timeZoneOffset, getTimeDocs]);

  const sampleData = {
    id: "47a0e27e-3d84-40f4-8c63-97677c4a800c",
    startTime: "2023-08-18T10:23:00.000Z",
    endTime: "2023-08-18T11:23:00.000Z",
    title: "test3 ",
  };

  const myEventsList: any = [
    {
      id: "someId",
      title: "Test",
      start: new Date("2023-08-18T10:23:00.000Z"),
      end: new Date("2023-08-18T11:23:00.000Z"),
      resource: {
        id: "1",
        title: "Client 1",
      },
    },
  ];

  // We had to manually edit the classes in index.css to allow components to work properly
  const components = {
    event: ({ event }: any) => {
      const data = event?.id;
      console.log("here");
      console.log(event);
      const primaryColour: EventColour = event?.project?.primaryColour;
      let bgColour =
        EventColourCssMap[primaryColour] ?? EventColourCssMap.default;
      console.log(bgColour);
      if (event?.project?.primaryColour) {
        bgColour = `${bgColour}`;
      }
      console.log(bgColour);
      if (data)
        return <div className={`${bgColour} rounded`}>{event.title}</div>;
      return null;
    },
  };

  // We can set the background of events with this property setter
  const customEventPropGetter = (event: any) => {
    const primaryColour: EventColour = event?.project?.primaryColour;
    const backgroundColor =
      EventColourCssHexMap[primaryColour] ?? EventColourCssHexMap.default;
    return {
      // className: className,
      style: {
        backgroundColor: backgroundColor,
      },
    };
  };
  // This is a custom func for the Day i.e 15
  const customDayPropGetter = (date: any) => {
    if (date.getDate() === 7 || date.getDate() === 15)
      return {
        className: "text-blue-500",
        style: {
          border: "solid 3px " + (date.getDate() === 7 ? "#faa" : "#afa"),
        },
      };
    else return {};
  };

  // This is a custom func for the timeslots in a given day
  const customSlotPropGetter = (date: any) => {
    if (date.getDate() === 7 || date.getDate() === 15)
      return {
        className: "bg-yellow-100",
      };
    else return {};
  };

  const now = new Date();
  const scrollToTimeDate = now.setHours(now.getHours() - 2);

  const currentWeekTimeDocuments = data?.getCurrentWeekTimeDocuments;
  const events: any = currentWeekTimeDocuments?.map((event) => {
    return {
      id: event?.id,
      title: event?.title,
      start: new Date(event?.startTime || ""),
      end: new Date(event?.endTime || ""),
      projectId: event?.projectId,
      project: event?.project,
    };
  });

  return (
    <Calendar
      // dayPropGetter={customDayPropGetter}
      // slotPropGetter={customSlotPropGetter}
      scrollToTime={new Date(scrollToTimeDate)}
      defaultView="week"
      // components={components}
      localizer={localizer}
      events={events}
      timeslots={4}
      selectable={true}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      onSelectEvent={(event) => {
        console.log(event);
        navigate(`/events/${event.id}`);
      }}
      onSelectSlot={(event) => {
        console.log(event);
      }}
      onNavigate={(date, view) => {
        console.log("#### onNavigate");
        console.log("#### date=", date);
        console.log("#### view=", view);
        // here we need to refetch possibly send in date for current week.
        setStartOfDay(
          new Date(new Date(date).setUTCHours(0, 0, 0, 0)).toISOString()
        );
        console.log(startOfDay);
        //this.setState({currentDate: date});
      }}
      onView={(view) => {
        console.log("#### onView");
        console.log("#### view=", view);
        //this.setState({currentView: view});
      }}
      eventPropGetter={customEventPropGetter}
    />
  );
};

export default CustomCalendar;
