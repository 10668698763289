import React from 'react';
import { Auth } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';

const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(), // Required when you use Cognito UserPools OR OpenID Connect
};
const url = process.env.REACT_APP_GRAPHQL_API_URL!;
const region = process.env.REACT_APP_AWS_REGION!;
const httpLink = new HttpLink({ uri: url });

//@ts-ignore
const link = ApolloLink.from([createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink({ url, region, auth }, httpLink)]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

interface ApolloWrapperProps {
  children: React.ReactNode;
}
export const ApolloWrapper = ({ children }: ApolloWrapperProps) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};