import { useMutation, useQuery } from "@apollo/client";
import { gql } from "../../../__generated__";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextInput } from "../../atoms/Inputs/TextInput";
import { Button } from "../../atoms/Buttons/Button";
import { useParams } from "react-router";

const GET_PROJECTS = gql(`
  query GetProjects {
    getProjects {
      id
      title
      description
      primaryColour
    }
  }
`);

const GET_TIME_DOCUMENT = gql(`
  query GetTimeDocument($timeDocumentId: String!) {
    getTimeDocument(timeDocumentId: $timeDocumentId) {
      id
      title
      description
      projectId
      startTime
      endTime
    }
  }
`);

const MUTATION_UPDATE_TIME_DOCUMENT = gql(`
  mutation UpdateTimeDocument($id: String!, $title: String!, $description: String, $projectId: String!, $startTime: String!, $endTime: String!) {
    updateTimeDocument(id: $id, title: $title, description: $description, projectId: $projectId, startTime: $startTime, endTime: $endTime) {
        id
        title
        description
        projectId
        startTime
        endTime
    }
  }
`);

interface IFormInputs {
  title: string;
  description?: string;
  projectId?: string;
  startTime: string;
  endTime: string;
}

const TimeDocumentFormEdit = () => {
  const { eventId } = useParams();
  //   const [
  //     getTimeDocument,
  //     {
  //       data: createTimeDocumenttData,
  //       loading: createTimeDocumentLoading,
  //       error: createTimeDocumentError,
  //     },
  //   ] = useMutation(MUTATION_CREATE_TIME_DOCUMENT);

  const { loading, error, data } = useQuery(GET_PROJECTS);

  const {
    loading: getTimeDocumentLoading,
    error: getTimeDocumentError,
    data: getTimeDocumentData,
  } = useQuery(GET_TIME_DOCUMENT, {
    variables: { timeDocumentId: eventId || "" },
  });

  const [
    updateTimeDocument,
    {
      data: updateTimeDocumentData,
      loading: updateTimeDocumentLoading,
      error: updateTimeDocumentError,
    },
  ] = useMutation(MUTATION_UPDATE_TIME_DOCUMENT);

  const projects = data?.getProjects ?? [];

  const defaultValues = {
    id: eventId || undefined,
    title: getTimeDocumentData?.getTimeDocument?.title || undefined,
    description: getTimeDocumentData?.getTimeDocument?.description || undefined,
    // This projectID don't waork right. it shows up as undefied?
    projectId: getTimeDocumentData?.getTimeDocument?.projectId || undefined,
    startTime: getTimeDocumentData?.getTimeDocument?.startTime
      ? new Date(getTimeDocumentData?.getTimeDocument?.startTime)
          .toLocaleString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            hour12: false,
            minute: "2-digit",
          })
          .replace(", ", "T")
      : undefined,
    endTime: getTimeDocumentData?.getTimeDocument?.endTime
      ? new Date(getTimeDocumentData?.getTimeDocument?.endTime)
          .toLocaleString("en-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            hour12: false,
            minute: "2-digit",
          })
          .replace(", ", "T")
      : undefined,
  };

  console.log(defaultValues);
  const { register, handleSubmit } = useForm<IFormInputs>();

  //   setValue("title", defaultValues.title || "");
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    console.log("submitted", data);
    const startTimeUtc = new Date(data.startTime).toUTCString();
    const endTimeUtc = new Date(data.endTime).toUTCString();
    console.log(startTimeUtc);
    console.log(endTimeUtc);
    eventId &&
      data.title &&
      data.startTime &&
      data.endTime &&
      data.projectId &&
      updateTimeDocument({
        variables: {
          id: eventId,
          title: data.title,
          description: data.description,
          projectId: data.projectId,
          startTime: startTimeUtc,
          endTime: endTimeUtc,
        },
      });
  };

  const formOptions = () => {
    return projects.map((project: any) => {
      return (
        <option key={project.id} value={project.id}>
          {project.title}
        </option>
      );
    });
  };
  if (getTimeDocumentLoading || loading) return <p>Loading...</p>;
  console.log(getTimeDocumentData);
  if (getTimeDocumentError) {
    return <p>Error &#x1F614; {getTimeDocumentError.message}</p>;
  }
  return (
    <>
      {updateTimeDocumentData && updateTimeDocumentData.updateTimeDocument ? (
        <p> Updated! </p>
      ) : null}
      <div className="rounded p-8">
        <form className="grid gap-y-2" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Title"
            defaultValue={defaultValues.title}
            useFormRegister={register("title")}
          />
          <TextInput
            label="Start time"
            type="datetime-local"
            defaultValue={defaultValues.startTime}
            useFormRegister={register("startTime")}
          />
          <TextInput
            label="End time"
            type="datetime-local"
            defaultValue={defaultValues.endTime}
            useFormRegister={register("endTime")}
          />
          <TextInput
            label="Description"
            defaultValue={defaultValues.description}
            useFormRegister={register("description")}
          />
          <select
            className="border"
            {...register("projectId")}
            defaultValue={defaultValues.projectId}
          >
            {formOptions()}
          </select>
          <div>
            <Button text="Submit" type="submit" />
          </div>
        </form>
      </div>
    </>
  );
  //   if (createTimeDocumentLoading || loading) return <p>Loading...</p>;
  //   if (createTimeDocumentError)
  //     return <p>Error &#x1F614; {createTimeDocumentError.message}</p>;

  //   return (
  //     <>
  //       {createTimeDocumenttData && createTimeDocumenttData.createTimeDocument ? (
  //         <p>Created!</p>
  //       ) : null}
  //       <div className="rounded p-8">
  //         <form className="grid gap-y-2" onSubmit={handleSubmit(onSubmit)}>
  //           <TextInput label="Title" useFormRegister={register("title")} />
  //           <TextInput
  //             label="Start time"
  //             type="datetime-local"
  //             useFormRegister={register("startTime")}
  //           />
  //           <TextInput
  //             label="End time"
  //             type="datetime-local"
  //             useFormRegister={register("endTime")}
  //           />
  //           <TextInput
  //             label="Description"
  //             useFormRegister={register("description")}
  //           />
  //           <select className="border" {...register("projectId")}>
  //             {formOptions()}
  //           </select>
  //           <div>
  //             <Button text="Submit" type="submit" />
  //           </div>
  //         </form>
  //       </div>
  //     </>
  //   );
};

export default TimeDocumentFormEdit;
