import { Link } from "react-router-dom";
import { EventColour, EventColourCssMap } from "../../../utils/types";

type ProjectCardProps = {
  project: any;
};

const ProjectCard = (props: ProjectCardProps) => {
  const { project } = props;
  const projectColour: EventColour = project.primaryColour || "default";
  return (
    <Link key={project.id} to={`${project.id}`}>
      <div className="w-full border p-8 rounded-xl mt-8 hover:bg-slate-300 hover:cursor-pointer">
        <div className="flex justify-between">
          {/* <div className="flex items-center"> */}
          <div className="flex items-center">
            <div
              className={`${EventColourCssMap[projectColour]} h-5 w-5 rounded-full border-2 mr-2`}
            ></div>
            <div className="">{project.title}</div>
          </div>
          <div>{project.description}</div>
        </div>
        {/* </div> */}
      </div>
    </Link>
  );
};

export default ProjectCard;
